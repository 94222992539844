<template>
  <div class="container px-4 sm:px-0 mx-auto flex flex-col py-5">

    <div class="
        mx-auto
        flex flex-wrap
        sm:flex-nowrap
        items-start
        justify-center
        text-center
        gap-x-4
        md:gap-x-10
      ">
      <!-- Foto -->
      <div class="w-full sm:w-auto">
        <go-back-arrow @goBack="goBack">Volver</go-back-arrow>
        <div class="pt-5 mx-auto flex-shrink-0 w-80 h-80 flex items-center">
          <img class="w-full h-full object-contain" :src="repuesto.image" alt="" />
        </div>
      </div>

      <!-- Datos -->
      <div class="pt-10 flex flex-col items-start w-full justify-start px-2 pb-5">
        <p class="text-2xl font-bold mb-4" v-if="repuesto.nombre">{{ repuesto.nombre }} ({{ repuesto.codigo }})</p>
        <div class="flex flex-col items-start">
          <span class="text-2xl font-bold">${{ repuesto.precio ? formatNumber(repuesto.precio) : '' }}</span>
          <div class="flex flex-col mb-2 items-start">
            <input-cantidad class="mt-4 mb-1" :min="0" :max="repuesto.stock - (getCarritoById[repuesto.id] || 0)"
              v-model:value="repuesto.cantidad" />
            <span class="text-xs">
              Stock: {{ repuesto.stock }}
            </span>
            <span v-if="getCarritoById[repuesto.id]" class="text-xs">
              En carrito: {{ getCarritoById[repuesto.id] }}
            </span>
          </div>
          <button :disabled="repuesto.cantidad == 0" :class="[
          { 'bg-blue-500 hover:bg-blue-400 cursor-pointer': repuesto.cantidad != 0 },
          { 'bg-gray-500 cursor-default': repuesto.cantidad == 0 },
          'transition duration-400 text-white rounded py-2 px-4 text-center']" @click="goToCheckout">
            Agregar al carrito
          </button>
        </div>

        <div class="mt-8 text-left" v-if="repuesto.compatibilidad?.length > 0">
          <p>Compatible con los siguientes modelos de máquinas:</p>
          <ul class="text-md text-gray-500 list-disc list-inside">
            <li v-for="producto in repuesto.compatibilidad" :key="producto.code" class="hover:underline cursor-pointer"
              @click="goToProducto(producto.code)">
              {{ producto.text }}
            </li>
          </ul>
        </div>

        <div class="mt-4 text-left" v-if="repuesto.replace?.length > 0">
          <p>Reemplaza a:</p>
          <ul class="text-md text-gray-500 list-disc list-inside">
            <li v-for="codRepuesto in repuesto.replace" :key="codRepuesto" class="">
              {{ codRepuesto.replacement_code }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import router from "@/router/index";
import { computed, reactive, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import { formatNumber } from "@/utils/functions";
import { apiGetRepuesto } from "@/utils/endpoints";
import { parseRepuesto } from "@/utils/parsers";

import InputCantidad from "../components/elements/InputCantidad.vue";
import GoBackArrow from "../components/elements/GoBackArrow.vue";

export default {
  name: "ProductDetails",
  components: { InputCantidad, GoBackArrow },
  setup() {
    const store = useStore();
    const route = useRoute();
    let carrito = computed(() => store.state.carrito);

    let repuestoId = ref("");
    repuestoId.value = route.params.repuestoId;

    let repuesto = reactive({
      id: repuestoId.value,
    });

    // Get Repuesto
    axios.get(apiGetRepuesto(repuestoId.value)).then((data) => {
      Object.assign(repuesto, parseRepuesto(data.data[0]));
    });

    const goToProducto = (productoId) => {
      router.push({ path: `/despiece/${productoId}` });
    };

    const getCarritoById = computed(() => {
      return carrito.value.reduce(
        (obj, item) => Object.assign(obj, { [item.id]: item.cantidad }),
        {}
      );
    });

    const goToCheckout = () => {
      if (repuesto.stock >= repuesto.cantidad) {
        let repuestoCarrito = {
          cantidad: repuesto.cantidad,
          id: repuesto.id,
          nombre: repuesto.nombre,
          compatibilidad: repuesto.compatibilidad,
          precio: repuesto.precio,
          stock: repuesto.stock,
          image: repuesto.image,
          producto: "",
        };

        store.commit("addProducts", [repuestoCarrito]);
        router.push({ name: `Checkout` });
      }
    };

    const goBack = () => {
      router.go(-1)
    }

    return { repuesto, goToProducto, goToCheckout, getCarritoById, formatNumber, goBack };
  },
};
</script>

<style>
</style>