<template>
  <div class="flex">
    <div
      :class="[
        { 'cursor-pointer': min < value },
        { 'text-gray-200': min >= value },
        'h-full py-1 px-2 rounded-l border border-r-0 flex items-center text-xl select-none bg-white',
      ]"
      @click="restar"
    >
      -
    </div>
    <input
      type="text"
      class="w-10 border-t border-b text-center"
      :value="value"
      :placeholder="placeholder"
      :disabled="disabled"
      required
      @keyup="isNumber()"
      @keypress="isNumber()"
    />
    <div
      :class="[
        { 'cursor-pointer': max > value },
        { 'text-gray-200': max == value },
        'h-full py-1 px-2 rounded-r border border-l-0 flex items-center text-xl select-none bg-white',
      ]"
      @click="sumar"
    >
      +
    </div>
  </div>
</template>

<script>
import { defineComponent, toRef } from "vue";

export default defineComponent({
  emits: ["update:value"],
  props: {
    placeholder: {
      type: String,
      required: false,
    },
    value: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    min: {
      type: Number,
      required: false,
      default: 0,
    },
    max: {
      type: Number,
      required: false,
    },
  },
  setup(props, { emit }) {
    let value = toRef(props, "value");
    let min = toRef(props, "min");
    let max = toRef(props, "max");

    const isNumber = (event) => {
      event = event ? event : window.event;
      let valor = event.target.value.replace(/[^0-9.]/g, "");

      if (valor >= min.value && valor <= max.value) {
        event.target.value = valor ? valor : 1;
        emit("update:value", parseFloat(event.target.value));
      } else {
        event.target.value = value.value;
        emit("update:value", parseFloat(value.value));
      }

      return true;
    };

    const restar = () => {
      let resta = value.value - 1;
      if (typeof min.value === "number" && resta < min.value) return false;
      emit("update:value", resta);
    };
    const sumar = () => {
      let suma = value.value + 1;
      if (typeof max.value === "number" && suma > max.value) return false;
      emit("update:value", suma);
    };

    if (!value.value){
      emit("update:value", 0);
    }

    return { isNumber, restar, sumar };
  },
});
</script>
